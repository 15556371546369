import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useUsers from '../../../hooks/useUsers';
import { validateEmail } from '../helpers';
import SubscribeByLinkService from '../api';

export default function useSubscribe() {
  const history = useHistory();
  const { dispatchUserData } = useUsers();
  const [monitoringType, setMonitoringType] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(null);
  const [isRequiredCode, setIsRequiredCode] = useState(null);
  const [isValidCode, setIsValidCode] = useState(null);
  const [name, setName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [email, setEmail] = useState(null);
  const [code, setCode] = useState(null);
  const [formError, setFormError] = useState(null);

  const onChangeName = e => {
    setName(e.target.value);
  };

  const onChangeSurname = e => {
    setSurname(e.target.value);
  };

  const onChangeEmail = e => {
    setEmail(e.target.value);
  };

  const onChangeMonitoringType = e => {
    setMonitoringType(e.target.value);
  };

  const onChangeCode = e => {
    setCode(e.target.value);
  };

  const requireCode = async () => {
    if (!validateEmail(email)) {
      setIsValidEmail(false);
      return;
    }

    setIsValidEmail(true);

    const res = await SubscribeByLinkService.requireCode(email);

    if (res?.errors) {
      setFormError(res?.errors);
      return;
    }

    setFormError(null);
    setIsRequiredCode(true);
  };

  const subscribe = async () => {
    if (code === null) {
      setIsValidCode(false);
      return;
    }

    setIsValidCode(true);

    const subscribeRes = await SubscribeByLinkService.subscribe({
      email,
      name,
      surname,
      code
    });

    if (subscribeRes?.errors) {
      setFormError(subscribeRes?.errors);
      setCode(null);
      return;
    }

    const accountSettings = await SubscribeByLinkService.requireAccountSettings(
      subscribeRes.token
    );

    if (accountSettings?.errors) {
      setFormError(accountSettings?.errors);
      setCode(null);
      return;
    }

    dispatchUserData({
      user: subscribeRes.user,
      token: subscribeRes.token,
      ...subscribeRes.user
    });

    setFormError(null);

    const monitoringTypes = accountSettings.subscribe.monitoring_types.map(
      type => {
        const newType = { ...type };

        newType.value = monitoringType === newType.label;

        if (monitoringType === 'all') {
          newType.value = true;
        }

        return newType;
      }
    );

    const monitoringSections = accountSettings.subscribe.monitoring_sections.map(
      section => {
        return { ...section, value: true };
      }
    );

    const monitoringRegions = accountSettings.subscribe.monitoring_regions.map(
      region => {
        const newRegion = { ...region };

        if (monitoringType === 'Региональный' || monitoringType === 'all') {
          newRegion.value = true;
        }

        return newRegion;
      }
    );

    const res = await SubscribeByLinkService.updateAccountSettings(
      {
        subscribe: {
          monitoring_regions: monitoringRegions,
          monitoring_sections: monitoringSections,
          monitoring_types: monitoringTypes
        }
      },
      subscribeRes.token
    );

    if (res?.errors) {
      setCode(null);
      setFormError(res?.errors);
      return;
    }

    history.push('/monitoring/released');
  };

  return {
    monitoringType,
    name,
    surname,
    email,
    code,
    isValidCode,
    isRequiredCode,
    isValidEmail,
    formError,
    subscribe,
    requireCode,
    onChangeCode,
    onChangeSurname,
    onChangeEmail,
    onChangeMonitoringType,
    onChangeName
  };
}
