import React, { useCallback } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import Monitoring from '../Monitoring';
import Editor from '../Main';
import Charts from '../Directory/Charts';
import Employees from '../Employees';
import useAuth from '../../hooks/useAuth';

const Root = () => {
  const {
    hasAuth,
    user: { role }
  } = useAuth();
  const location = useLocation();

  const Component = useCallback(() => {
    if (hasAuth) {
      // eslint-disable-next-line default-case
      switch (role) {
        case 'user':
          return <Monitoring />;

        case 'editor':
          return <Editor />;

        case 'analyst':
          return <Charts />;

        case 'admin':
          return <Employees />;

        case 'pr_manager':
          return <Editor />;

        default:
          return <Redirect to="/login" />;
      }
    } else {
      return <Redirect to="/login" />;
    }
  }, [hasAuth, role]);

  if (location.pathname === '/subscribe-by-link') {
    return <Redirect to="/subscribe-by-link" />;
  }

  return <Component />;
};

export default Root;
