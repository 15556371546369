// @flow
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Cookie from 'js-cookie';
import { YMInitializer } from 'react-yandex-metrika';
import { ThemeProvider } from '@material-ui/core/styles';
import PrivateRoute from './components/PrivateRoute';
import useAuth from './hooks/useAuth';
import useUsers from './hooks/useUsers';
import Login from './pages/Login';
import Remind from './pages/Login/Remind';
import Request from './pages/Login/Request';
import Notes from './pages/Notes';
import Unsubscribe from './pages/Unsubscribe';
import SubscribeByLink from './pages/SubscribeByLink';
import { clearAllData } from './utils/methods';
import { server } from '@constants/servers';
import { ROUTES } from './routes';
import EventSnackbar from './components/EventSnackbar';
import { SET_EVENT } from './store/event/types';
import mainMaterialTheme from './styles/mainMaterialTheme';
import styles from './App.scss';

const App = () => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = urlParams.get('token');
  const { hasAuth } = useAuth();
  const { dispatchUserData } = useUsers();

  const autoLogin = React.useCallback(async () => {
    const userByToken = await axios
      .post(`${server}/api/v1/account/user_by_token/${tokenFromUrl}`)
      .then(async user => {
        const { data } = user;
        if (data?.token && data?.user) {
          await Cookie.set('token', data.token, { expires: 7, path: '' });

          if (data?.user?.role === 'super') {
            const newUser = {
              ...data,
              user: { ...data.user, role: 'user', isSuper: true }
            };
            await dispatchUserData(newUser);
          } else {
            await dispatchUserData(data);
          }

          window.location.replace(window.location.pathname);
        }

        if (data?.error) {
          dispatch({
            type: SET_EVENT,
            payload: {
              open: true,
              message: 'Неверный или устаревший токен',
              status: 'error'
            }
          });
          clearAllData();
        }
      })
      .catch(err => console.log(err));
  }, [hasAuth, window.location.search]);

  React.useEffect(() => {
    if (!hasAuth && tokenFromUrl) {
      autoLogin();
    }
  }, [hasAuth, tokenFromUrl, window.location.search]);

  const Routes = () =>
    React.useMemo(
      () => (
        <>
          <Route component={Remind} path="/login/remind" />
          <Route component={Request} path="/login/request" />
          <Route component={Login} path="/login" />
          <Route component={Notes} path="/notes/:id" />
          <Route component={Unsubscribe} path="/unsubscribe" />
          <Route component={SubscribeByLink} path="/subscribe-by-link" />
          {ROUTES.map(route => (
            <PrivateRoute {...route} key={uuidv4()} />
          ))}
        </>
      ),
      []
    );
  const id = JSON.parse(localStorage.getItem('state'))?.user?.id || '';
  const role = JSON.parse(localStorage.getItem('state'))?.user?.role || '';

  return (
    <ThemeProvider theme={mainMaterialTheme}>
      <BrowserRouter>
        <div className={styles.Root}>
          <YMInitializer
            accounts={[92353848]}
            options={{
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true,
              ecommerce: 'dataLayer',
              clientRole: `${role}`,
              clientID: `${id}`
            }}
          />
          <Switch>
            <Routes />
          </Switch>
          <EventSnackbar />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
