// @flow

// Pages for all users
import Root from './pages/Root';
import MailingSettings from './pages/MailingSettings';
import Notes from './pages/Notes';

// User Pages
import Bookmarks from './pages/Bookmarks';

// Analyst Pages
import PRValue from './pages/Handbook/PRValue';
import Charts from './pages/Directory/Charts';
import ReportsHistory from './pages/ReportsHistory';

// User && Editor Pages
import Monitoring from './pages/Monitoring';
import Articles from './pages/Articles';
import Article from './pages/Article';
import Group from './pages/Group';

// Editor && Analyst Pages
import SMI from './pages/Handbook/SMI';
import Operators from './pages/Handbook/Operators';
import PRCampaign from './pages/Handbook/PRCampaign';
import Regions from './pages/Handbook/Regions';
import Handbooks from './pages/Handbook';
import Tags from './pages/Handbook/Tags';
import Editor from './pages/Main';

export const ROUTES = [
  {
    exact: true,
    component: Root,
    path: '/'
  },
  {
    component: Editor,
    path: '/editor'
  },
  {
    component: Charts,
    path: '/create_report'
  },
  {
    component: ReportsHistory,
    path: '/reports_history'
  },
  {
    component: Charts,
    path: '/report/:id'
  },
  {
    component: Monitoring,
    path: '/monitoring/released'
  },
  {
    component: Monitoring,
    path: '/monitoring/prepared'
  },
  {
    component: Articles,
    path: '/articles/section'
  },
  {
    component: Group,
    path: '/articles/group/:id'
  },
  {
    component: Article,
    path: '/article/:id'
  },
  {
    component: SMI,
    path: '/handbooks/smi'
  },
  {
    component: Tags,
    path: '/handbooks/tags'
  },
  {
    component: Operators,
    path: '/handbooks/operators'
  },
  {
    component: PRCampaign,
    path: '/handbooks/pr_campaign'
  },
  {
    component: Regions,
    path: '/handbooks/regions'
  },
  {
    component: PRValue,
    path: '/handbooks/pr_value'
  },
  {
    exact: true,
    component: Handbooks,
    path: '/handbooks'
  },
  {
    component: MailingSettings,
    path: '/mailingSettings'
  },
  {
    exact: true,
    component: Notes,
    path: '/notes'
  },
  {
    component: Bookmarks,
    path: '/bookmarks'
  }
];
