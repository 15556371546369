import React from 'react';
import { FormControl, InputLabel, Button } from '@material-ui/core';
import { MenuItem, Select } from '@mui/material';
import Input from './components/input';
import useSubscribe from './hooks/use-subscribe';
import styles from './styles.sass';

export default function SubscribeByLink() {
  const {
    monitoringType,
    formError,
    code,
    isRequiredCode,
    isValidCode,
    name,
    isValidEmail,
    email,
    surname,
    requireCode,
    onChangeCode,
    subscribe,
    onChangeEmail,
    onChangeMonitoringType,
    onChangeSurname,
    onChangeName
  } = useSubscribe();

  const renderButtons = () => {
    if (isRequiredCode && formError === null) {
      return (
        <Button
          disabled={code === null || code === '' || code.length !== 4}
          onClick={subscribe}
          variant="outlined"
          color="primary"
        >
          Подписаться
        </Button>
      );
    }

    return (
      <Button
        disabled={
          monitoringType === null ||
          name === null ||
          name === '' ||
          surname === null ||
          surname === '' ||
          email === null ||
          email === ''
        }
        onClick={requireCode}
        variant="outlined"
        color="primary"
      >
        Получить код
      </Button>
    );
  };

  const renderCodeInputField = () => {
    if (isRequiredCode && !formError) {
      return (
        <Input
          placeholder="Введите код"
          value={code}
          onChange={onChangeCode}
          isValid={isValidCode}
          errorMessage="Поле обязательное"
        />
      );
    }

    return null;
  };

  return (
    <form className={styles.subscribeForm}>
      {formError !== null ? (
        <p className={styles.InputErrorText}>
          Ошибка {Object.keys(formError)[0]}: {Object.values(formError)[0]}
        </p>
      ) : null}
      <Input value={name} onChange={onChangeName} placeholder="Имя" />
      <Input value={surname} onChange={onChangeSurname} placeholder="Фамилия" />
      <Input
        placeholder="Email (@megafon.ru)"
        value={email}
        onChange={onChangeEmail}
        isValid={isValidEmail}
        errorMessage="Разрешены только @megafon.ru почты."
      />
      <FormControl>
        {monitoringType === null ? (
          <InputLabel
            className={styles.SelectLabel}
            id="choose-type-of-monitoring"
          >
            Выберите тип мониторинга
          </InputLabel>
        ) : null}
        <Select
          value={monitoringType}
          onChange={onChangeMonitoringType}
          labelId="choose-type-of-monitoring"
          className={styles.Select}
        >
          <MenuItem value="Федеральный">Федеральный</MenuItem>
          <MenuItem value="Региональный">Региональный</MenuItem>
          <MenuItem value="all">Федеральный и Региональный</MenuItem>
        </Select>
      </FormControl>
      {renderCodeInputField()}
      {renderButtons()}
    </form>
  );
}
