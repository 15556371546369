import React from 'react';
import { Input as MuiInput } from '@material-ui/core';
import styles from '../styles.sass';

export default function Input({
  value,
  onChange,
  isValid,
  errorMessage,
  placeholder
}) {
  return (
    <div className={styles.InputContainer}>
      <MuiInput
        value={value}
        onChange={onChange}
        className={`${styles.Input} ${
          isValid !== false ? '' : styles.InputError
        }`}
        placeholder={placeholder}
      />
      {isValid !== false ? null : (
        <span className={styles.InputErrorText}>{errorMessage}</span>
      )}
    </div>
  );
}
