import { server } from '../../../constants/servers';

class SubscribeByLinkService {
  static async requireCode(email) {
    try {
      await fetch(`${server}/api/v1/auth/request-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          contact: email
        })
      });
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async subscribe({ email, name, surname, code }) {
    try {
      const res = await fetch(`${server}/api/v1/auth/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          first_name: name,
          last_name: surname,
          otp: code
        })
      });

      return await res.json();
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async requireAccountSettings(token) {
    try {
      const res = await fetch(`${server}/api/v1/account/settings`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      return await res.json();
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async updateAccountSettings(settings, token) {
    try {
      await fetch(`${server}/api/v1/account/settings`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(settings)
      });
    } catch (err) {
      console.error(err);
      return err;
    }
  }
}

export default SubscribeByLinkService;
